import { CLink } from '@/CLink';
import { PlainBtn, PrimaryBtn } from '@/buttons';
import { ReactComponent as ArrowIcon } from '@a/icons/mini-arrow.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as Logo } from '@a/images/layout/logo.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import useLocationData from '@s/hooks/useLocationData';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DesktopNav } from './DesktopNavPrimitive';
import { LangToggle } from './LangToggle';
import { MobNav } from './MobNav';
import { navLinks, siteInfo } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexSpace, px } from './styles/classes';

const Navbar = styled.header<{ scrolled: boolean }>`
    ${px};
    ${flexSpace};
    padding-top: 8px;
    padding-bottom: 8px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-right: var(--removed-body-scroll-bar-size);
    border-bottom: 1px solid transparent;
    z-index: 100;
    transition:
        border-color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

    ::before,
    ::after {
        content: '';
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: -1px;
        transition: opacity 0.3s ease-in-out;
    }

    ::before {
        background-image: linear-gradient(
            90deg,
            rgba(244, 244, 244, 0) 0%,
            #f4f4f4 50.41%,
            rgba(244, 244, 244, 0) 100%
        );
    }

    ::after {
        background-image: ${({ theme }) => theme.colors.gradient};
        opacity: 0;
    }

    @media (min-width: 1280px) and (max-width: 1499px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (min-width: 1921px) {
        padding: 8px calc((100vw - 1790px) / 2);
    }

    ${({ scrolled }) =>
        scrolled &&
        css`
            background-color: #bb9c57;

            ::before {
                opacity: 0;
            }

            ::after {
                opacity: 1;
            }
        `};
`;

const LogoLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 1279px) {
        > svg {
            width: 64px;
            height: 64px;
        }
    }
`;

export const CallBtn = styled(PlainBtn)`
    margin-right: 32px;
    margin-left: 32px;

    @media (min-width: 414px) {
        margin-right: 48px;
        margin-left: 48px;
    }

    @media (min-width: 1280px) {
        display: none;
    }
`;

const DesktopNavMenu = styled(DesktopNav)`
    ${flexSpace};
    margin: 0 auto;

    @media (max-width: 1279px) {
        display: none;
    }
`;

const contentStyles = css`
    border-width: 1px;
    border-style: solid;
    border-image-slice: 1;
    border-image-source: ${theme.colors.gradient};
    background-color: #bb9c57;
    overflow: hidden;
    position: absolute;
    top: calc((1.25rem * 1.5) + 20px);
    width: max-content;
    z-index: 101;
    left: 0;
    box-shadow:
        0 0 0 500vmax rgb(176 132 33 / 0.5),
        0 0 20px rgb(176 132 33 / 0.5);

    > ul {
        max-height: calc(100vh - 110px);
        overflow-y: auto;
    }
`;

const DesktopBtn = styled(PrimaryBtn)`
    display: none;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    flex-shrink: 0;
    margin-left: 8px;

    ::before {
        background: rgba(24, 24, 24, 0.62);
    }

    svg path {
        fill: ${({ theme }) => theme.colors.white};
    }

    > span > span {
        display: none;
        margin-right: 0.5ch;
    }

    @media (min-width: 1280px) {
        display: flex;
        margin-left: 16px;
    }

    @media (min-width: 1440px) {
        > span > span {
            display: block;
        }
    }
`;

const Observe = styled.span`
    position: absolute;
    width: 1px;
    height: 1px;
    top: 10px;
    left: 0;
`;

export const HomeLogo = ({ className }: { className?: string }) => (
    <LogoLink to="/" aria-label="ka dental" css={className}>
        <Logo />
    </LogoLink>
);

export const Nav = () => {
    const [scrolled, setScrolled] = useState(false);

    const observeRef = useRef<HTMLElement | null>(null);

    const visible = useIntersectionObserver(observeRef, {});

    useEffect(() => {
        const timeout = setTimeout(() => {
            setScrolled(!visible);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);

    const { landingPage } = useLocationData();

    return (
        <Fragment>
            <Navbar scrolled={scrolled}>
                <HomeLogo />

                <DesktopNavMenu links={navLinks.slice(0, -2)} contentStyles={contentStyles} />

                <LangToggle />

                <CallBtn
                    as={CLink}
                    to={landingPage ? siteInfo.phone2.link : siteInfo.phone.link}
                    aria-label={`call us at ${
                        landingPage ? siteInfo.phone2.text : siteInfo.phone.text
                    }`}
                >
                    <PhoneIcon />
                </CallBtn>

                <DesktopBtn
                    light
                    as={CLink}
                    to={landingPage ? siteInfo.phone2.link : siteInfo.phone.link}
                >
                    {landingPage ? siteInfo.phone2.text : siteInfo.phone.text} <ArrowIcon />
                </DesktopBtn>

                <DesktopBtn light as={CLink} to={navLinks[navLinks.length - 1].link}>
                    <span>Book an </span> Appointment
                    <ArrowIcon />
                </DesktopBtn>
                <MobNav />
            </Navbar>
            <Observe ref={observeRef} />
        </Fragment>
    );
};
